import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import RelatedRateOptions from "../components/Repeating/RelatedRateOptions";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <Layout>
      <SearchEngineOptimization
        title="Interest-Only Mortgage | Right Start | Mortgage Lender"
        description="Learn about the benefits of an interest-only mortgage, where you pay just interest for the first several years of your home loan. Call today to request rates!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden py-20 md:py-28">
        <div className="absolute left-0 top-0 h-full w-full ">
          <StaticImage
            src="../images/3.0 Mortgage Rate Options/Hero.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative max-w-[640px]">
            <h1 className="text-white">Interest-Only Mortgages</h1>
            <p className="mb-0 text-white">
              An interest-only mortgage is an attractive option if you have a
              variable income because your initial monthly payments are usually
              lower.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="What is it?"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="How it works"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="Timeframe"
                  stripHash
                />
              </li>

              <li>
                <AnchorLink
                  to={url + "#section-4"}
                  title="Examples"
                  stripHash
                />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  What is an Interest-Only Mortgage?
                </h2>
                <p className="mb-0">
                  When you have an interest-only mortgage, you pay just the
                  interest for the first several years of the loan. Then the
                  principal is amortized into the payment schedule after that
                  initial period. At the end of the interest-only period, you
                  also have the option to make a balloon payment or refinance
                  and get a new loan.
                </p>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  How Does an Interest-Only Mortgage Work?
                </h2>
                <p className="mb-0">
                  Interest-only loans are typically structured as a 3/1, 5/1,
                  7/1, or 10/1 adjustable-rate mortgage (ARM), meaning you would
                  pay interest only for 3, 5, 7, or 10 years. After that period,
                  you would begin paying principal and interest, and the
                  mortgage rate may change as the market changes. Fixed-rate
                  interest-only loans are also available, but they’re not as
                  common.
                </p>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  How Long Does an Interest-Only Mortgage Last?
                </h2>
                <p className="mb-0">
                  Interest-only loans last as long as any other loan—typically
                  15 or 30 years. The only difference is that you’ll pay
                  interest for a period of time and then both principal and
                  interest for the rest of the loan period.
                </p>
              </div>

              <div
                id="section-4"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  Types of Interest-Only Mortgage Loans
                </h2>
                <ul className="styled-list-checkmark">
                  <li>3/1 Interest-Only ARM</li>
                  <li>5/1 Interest-Only ARM</li>
                  <li>7/1 Interest-Only ARM</li>
                  <li>10/1 Interest-Only ARM</li>
                  <li>Fixed-Rate Interest-Only Loan</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <ValueProps />
      <About />
      <RelatedRateOptions hiddenRate={3} />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
